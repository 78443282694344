.about-container {
    padding: 50px;
    background-color: #f8f9fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .about-header {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .about-text {
    font-size: 1.25rem;
    max-width: 800px;
    text-align: center;
  }
  