nav a.active {
  font-weight: bold;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  backface-visibility: hidden;
}

.glassmorphism:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  transform: scale(1.02);
}

.social-media-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.social-media-icon:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  transform: translateY(-6px);
}

.nav-button {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px !important;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 0 16px ;
  padding: 8px 16px !important;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) ;
}

.nav-button:hover {
  border-color: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}

.nav-button-highlight {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  position: relative;
  border-radius: 0px !important;
  cursor: pointer;
  font-weight: bold;
  margin: 0 16px ;
  padding: 8px 16px !important;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) ;
  background: white !important;
  color: black !important;
}

.nav-button-highlight:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
  border-color: rgba(0, 0, 0, 0.7) !important;
}


.nav-button:focus {
  outline: none;
  text-decoration: none;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* Add margin for wrapped elements */
.nav-button,
.social-media-icon {
  margin-bottom: 16px;
}

.header-animation {
  transform-origin: top;
  backface-visibility: hidden;
}

@keyframes bookFlip {
  0% {
    transform: perspective(1200px) rotateX(0deg);
  }
  100% {
    transform: perspective(1200px) rotateX(-180deg);
  }
}

@keyframes shining-text {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.shining-text {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.4) 75%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shining-text 5s linear infinite;
}

.typewriter-text {
  position: relative;
  z-index: 1;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  backdrop-filter: blur(10px);
}

.typewriter-text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  filter: blur(10px);
}
.terminal-background {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px 12px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.professional-background {
  position: relative;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px 12px;
  margin-top: 8px;
  backdrop-filter: blur(5px);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 2.5rem;
}

.text-container {
  position: relative;
  overflow: hidden;
  height: 3rem; /* Adjust this value based on your desired text height */
}

.professional-background:hover {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}
