.glassmorphism-dialog-container {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  z-index: 100;
  width: 100%;
}

.glassmorphism-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
}

.glassmorphism-dialog p {
  margin: 0;
  color: #fff;
}

.glassmorphism-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.glassmorphism-dialog-buttons button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
}

.glassmorphism-dialog-buttons button.yes {
  font-weight: bold;
}

/* GlassmorphismDialog styles for mobile devices */
@media (max-width: 768px) {
  .glassmorphism-dialog-container {
    justify-content: center;
    padding: 0;
    bottom: 0;
  }

  .glassmorphism-dialog {
    border-radius: 8px;
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem; /* Add this line */
  }

  .glassmorphism-dialog p {
    font-size: 14px;
    margin: 0;
  }

  .glassmorphism-dialog-buttons {
    display: flex;
  }

  .glassmorphism-dialog-buttons button {
    font-size: 14px;
    padding: 8px 16px;
    margin-left: 8px;
  }
}
