.app-icons-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 53%;
    height: 100%;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .app-icons-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: -1;
  }
  
  .app-icons-row {
    display: flex;
    padding: 0 16px;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .row-even {
    animation-name: marquee-left;
    direction: ltr;
  }
  
  .row-odd {
    animation-name: marquee-right;
    direction: rtl;
  }

  .app-icon-wrapper {
    width: 200;
    height: 200;
    display: inline-block;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .app-icon {
    width: 150px;
    height: auto;
    margin: 4px;
    opacity: 0.5;
    transition: transform 0.5s, opacity 0.5s;
    object-fit: fill;
  }
  
 /* Common Mobile Screen Resolutions */
@media (max-width: 414px) {
  .app-icon {
    width: 225px; /* Slightly larger for small mobile screens */
  }
}

/* Common Desktop Screen Resolutions */
@media (min-width: 1280px) {
  .app-icon {
    width: 225px; /* Optimal for 412x915 and similar */
  }
}

@media (min-width: 1366px) {
  .app-icon {
    width: 250px; /* Larger for medium desktop screens */
  }
}

@media (min-width: 1920px) {
  .app-icon {
    width: 275px; /* Larger for wide desktop screens */
  }
}
/* 2K Screen Resolution */
@media (min-width: 2560px) {
  .app-icon {
    width: 300px; /* Adjust for 2K resolution */
  }
}

/* 4K Screen Resolution */
@media (min-width: 3840px) {
  .app-icon {
    width: 400px; /* Adjust for 4K resolution */
  }
}
    
    .app-icon:hover {
    transform: scale(1.2);
    opacity: 1;
    }
    
    @keyframes marquee-left {
    0% {
    transform: translateX(0%);
    }
    100% {
    transform: translateX(-100%);
    }
    }
    
    @keyframes marquee-right {
    0% {
    transform: translateX(0%);
    }
    100% {
    transform: translateX(100%);
    }
    }
    
    .app-icon-clicked {
    animation: scaleUp 0.3s forwards;
    }
    
    @keyframes scaleUp {
    0% {
    transform: scale(1);
    }
    100% {
    transform: scale(2);
    }
    }
  